import React, { useState } from 'react'
import {
  Button,
  Toast,
  toastManager,
  ToastManagerOptions,
} from '@therms/atalaya'

function MsgWithDetails({
  details,
  message,
}: {
  details?: string | undefined
  message?: any
}) {
  const [viewDetails, setViewDetails] = useState(false)
  return (
    <div>
      <div>{message}</div>
      {details && !viewDetails && (
        <Button variant="critical" subtle onClick={() => setViewDetails(true)}>
          View Details
        </Button>
      )}
      {viewDetails && (
        <div className="font-light">
          <em>{details}</em>
        </div>
      )}
    </div>
  )
}
/**
 * Alerts are for showing the user feedback on the UI that is directly in front of them (what they are looking at
 * or interacting with).
 *
 * Notifications are for all other "notifications" that need the user's attention
 */
export const Alerts = {
  error: (
    message,
    details?: string,
    opts?: ToastManagerOptions & {
      showCloseButton?: boolean
      title?: React.ReactNode
    },
  ): string | number =>
    toastManager(
      <Toast
        hideCloseButton={!opts?.showCloseButton}
        title={(opts?.title || 'Error') as string}
      >
        <MsgWithDetails details={details} message={message} />
      </Toast>,
      {
        autoClose: 3000,
        hideProgressBar: true,
        position: toastManager.POSITION.BOTTOM_CENTER,
        variant: 'critical',
        ...(opts || {}),
      },
    ),
  info: (
    message,
    opts?: ToastManagerOptions & {
      showCloseButton?: boolean
      title?: React.ReactNode
    },
  ): string | number =>
    toastManager(
      <Toast
        hideCloseButton={
          typeof opts?.showCloseButton === 'boolean'
            ? !opts.showCloseButton
            : false
        }
        title={opts?.title as string}
      >
        {message}
      </Toast>,
      {
        autoClose: 3500,
        hideProgressBar: true,
        position: toastManager.POSITION.BOTTOM_CENTER,
        variant: 'info',
        ...(opts || {}),
      },
    ),
  success: (
    message,
    opts?: ToastManagerOptions & {
      showCloseButton?: boolean
      title?: React.ReactNode
    },
  ): string | number =>
    toastManager(
      <Toast
        hideCloseButton={!opts?.showCloseButton}
        title={(opts?.title || 'Success') as string}
      >
        <div>{message}</div>
      </Toast>,
      {
        autoClose: 2000,
        hideProgressBar: true,
        position: toastManager.POSITION.BOTTOM_CENTER,
        variant: 'positive',
        ...(opts || {}),
      },
    ),
}
